import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'
import Product from "components/products/Product";
function HomeCollection(props){
  const [home_collection, setHomeCollection] = useState([]);

  useEffect(() => {
      if(props.home_collection){
          setHomeCollection(props.home_collection)
      }
  }, [props.home_collection]);

    const labelId = `home_collection-child-label-${home_collection.id}`;
    let slide_subclass = "slide-container-two" 
    let image_format = "medium"
    let skeleton_height = 188
    if (home_collection.product_limit == 5){ 
        slide_subclass = "slide-container-five" 
        image_format = "medium"
        skeleton_height = 280 
    } else if (home_collection.product_limit > 4){ 
        slide_subclass = "" 
        image_format = "medium"
        skeleton_height = 324 
    }else if (home_collection.product_limit == 4) {
        slide_subclass = "slide-container-four" 
        image_format = "medium"
        skeleton_height = 324
    }else if (home_collection.product_limit == 3){ 
        slide_subclass = "slide-container-three" 
        image_format = "large"
        skeleton_height = 394
    }else if (home_collection.product_limit == 1){ 
        slide_subclass = "slide-container-one" 
        image_format = "large"
        skeleton_height = 352
    }else{ 
        slide_subclass = "slide-container-two" 
        skeleton_height = 532
        image_format = "large"
    }
  return (
        
    <section className="posts">
        {home_collection &&
            <>
              <header key={labelId}>
                <h2>{home_collection.name}</h2>
                <div className="subtitle">{home_collection.subtitle}</div>
                <div className="more_index"><a className="more" href={"/product_collection/"+ home_collection.id} target="_blank" rel="noopener noreferrer">{home_collection.link_text}</a></div>
              </header>

              <section className={"slide-container "+slide_subclass}>
                {home_collection.available_and_limited_products && home_collection.available_and_limited_products.length > 0 && home_collection.available_and_limited_products.map((product) => {
                  return (
                    <Product
                      key={"product" + product.id}
                      product={product}
                      image_format={image_format}
                      skeleton_height={skeleton_height}
                      skeleton_animation="none" // Desactivar la animación del Skeleton
                    />
                  );
                })}
              </section>
              <div className="posts-footer">
              </div>
            </>

        }
    </section>
  );
}

export default HomeCollection