import React, { useState, useEffect } from 'react';

function HomeCover(props) {
  const [cover, setCover] = useState({});
  const [imageSRC, setImage] = useState("");

  useEffect(() => {
    try {
      const covers = typeof props.covers === "string" ? JSON.parse(props.covers) : props.covers;
      console.log("Carga cover: ", covers, covers.cover_url);
      setCover(covers);
      setImage(covers.cover_url);
    } catch (error) {
      console.error("Error parsing covers JSON: ", error);
      setCover(null);
      setImage(null);
    }
  }, [props.covers]);

  const backgroundStyle = {
    backgroundImage: `url(${imageSRC})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '459px',
    transition: 'opacity 0.3s ease-in-out',
    borderRadius: '12px',
    opacity: 1,
  };

  return (
    <section className="carousel">
      <section className="hero-slider ready home-slides-container">
        <article className={`slide`}>
          <div className="background-image skeleton" style={backgroundStyle}></div>
          <div className="slide-info">
            <p>{cover.message}</p>
            <h1>{cover.title}</h1>
            <a className="call-to-action" title="" href={cover.button_url} target="_blank" rel="noopener noreferrer">
              {cover.button_message}
            </a>
          </div>
        </article>
      </section>
    </section>
  );
}

export default HomeCover;