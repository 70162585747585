import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'
import Product from "components/products/Product";
function ProductCollection(props){
  const [products, setProducts] = useState([]);

  useEffect(() => {
      if(props.products){
        setProducts(props.products)
        console.log("products", products)
      }
  }, [props.products]);

    const labelId = `product-collection-${props.object_id}`;
    let skeleton_height = 394
    let image_format = "large"
    let slide_subclass = "col-xs-12 col-sm-4 col-md" 
  return (
        
    <section className="" key={labelId}>
        {products &&
            <>
              
              <section id="landing-page" className={"row"}>
                {products && products.length > 0 && products.map((product) => {
                  return (
                    <article className={"col landing-page " + slide_subclass}>
                    <Product
                      key={"product" + product.id}
                      product={product}
                      image_format={image_format}
                      skeleton_height={skeleton_height}
                      extra_info={true}
                      skeleton_animation="none"
                    />
                    </article>
                  );
                })}
              </section>
            </>

        }
    </section>
  );
}

export default ProductCollection