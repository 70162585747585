import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import i18n from 'i18n-js'
import { ThemeProvider } from '@material-ui/styles';
import themeViewnia from "components/utils/Theme";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Product from "components/products/Product";
import DateFnsUtils from '@date-io/date-fns';
import Radio from '@material-ui/core/Radio';
import Skeleton from '@material-ui/lab/Skeleton';
import {mkt_money} from "components/utils/Money.jsx";
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
  gtmId: 'GTM-W6W9H6NJ'
}
TagManager.initialize(tagManagerArgs)

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: "20%",
  },
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  dropdownStyle: 
  {
    border: "1px solid #9b9b9b",
    "& ul": {
      backgroundColor: "#fff",
    },
    "& li": {
        fontSize: "1em",
    },
  },
}));

function Products(props){
  const [products, setProducts] = useState([]);
  const [optional_products, setOptionalProducts] = useState([]);
  const [optinal_results_text, setOptinalResultsText] = useState("");
  const [optinal_results, setOptinalResults] = useState(false);
  
  
  const [activities, setActivities] = useState([]);
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const [activityValue, setActivityValue] = useState("");
  const [regionValue, setRegionValue] = useState("");
  const [timeValue, setTimeValue] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [dateValueForSelect, setDateValueForSelect] = useState("");
  const [isSearch, setIsSearch] = useState(false)
  const [activityObject, setActivityObject] = useState("");
  const [regionObject, setRegionObject] = useState("");
  
  const [stringSearch, setStringSearch] = useState("");
  const [titleSearch, setTitleSearch] = useState("");
  const [descriptionCategory, setDescriptionCategory] = useState(false);
  

  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setDateValue(date);
    setDateValueForSelect("current_date");
  };

  
  const classes = useStyles();

 
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let activity = ""
    let region = ""
    let time = ""
    let date = ""
    console.log("actividad: ", props.activity)
    if (urlParams.has('activity')){
      activity = (urlParams.get('activity'))
      setActivityValue(urlParams.get('activity'))
    }
    if(props.activity){
      activity = props.activity
      setActivityValue(props.activity)
    }
    if (urlParams.has('region')){
      region = (urlParams.get('region'))
      setRegionValue(urlParams.get('region'))
    }
    if (urlParams.has('time')){
      time = (urlParams.get('time'))
      setTimeValue(urlParams.get('time'))
    }
    if (urlParams.has('date')){
      date = (new Date(Date.parse(urlParams.get('date'))))
      setDateValue(date)
      setSelectedDate(date);
      setDateValueForSelect("current_date")
    }

    get_products(activity, region, time, date);
  }, []);

  useEffect(() => {
    if(activityValue){
      setActivityObject(activities.find(activity => activity.id ==  activityValue))
    }
    if(regionValue){
      setRegionObject(regions.find(region => region.id ==  regionValue))
    }
  }, [activityValue, regionValue]);

  function search(){
    setIsSearch(true)
    get_products(activityValue, regionValue, timeValue, dateValue);
  }


  function clear(){
    setActivityValue("");
    setRegionValue("");
    setTimeValue("");
    setDateValue("");
    setActivityObject("");
    setRegionObject("");
    setStringSearch("");
    setSelectedDate("");
    get_products("", "", "", "");
    setIsSearch(false)
  }
  
  function get_products(activityValue_t, regionValue_t, timeValue_t, dateValue_t){
    setLoading(true)
    return fetch(`/get_products.json?activity=${activityValue_t}&region=${regionValue_t}&time=${timeValue_t}&date=${dateValue_t}
    `)
      .then(response => response.json())
      .then(json => {
        setProducts(json.excursions)
        createDataLayer(json.excursions)
        setActivities(json.activities)
        setRegions(json.regions)
        setLoading(false)
        let activitiesObj = {}
        let regionsObj = {}
        
        if(activityValue_t){
          activitiesObj = (json.activities.find(activity => activity.id ==  activityValue_t))
        }
        if(regionValue_t){
          regionsObj = (json.regions.find(region => region.id ==  regionValue_t))
        }

        if(json.excursions.length > 0){
          setDescriptionCategory((activitiesObj && activitiesObj.description) ? activitiesObj.description : i18n.t("products_search.subtext"))
          setStringSearch([(activitiesObj ? activitiesObj.name : ""), (regionsObj ? regionsObj.name : ""), (timeValue_t ? i18n.t("excursions." + timeValue_t) : ""), (dateValue_t ? dateValue_t.toLocaleDateString() : "")].filter(Boolean).join(", "))
        }else{
          if(regionsObj.name  && activitiesObj.name ){
            setDescriptionCategory((activitiesObj && activitiesObj.description) ? activitiesObj.description : i18n.t("products_search.subtext"))
            setStringSearch(i18n.t('searches.category_products_in_zone_no_result', {category_name: (activitiesObj ? activitiesObj.name : ""), zone_name: (regionsObj ? regionsObj.name : "")}))
          }else if(regionsObj.name ){
            setStringSearch(i18n.t('searches.all_products_in_zone_no_result', {zone_name: (regionsObj ? regionsObj.name : "")}))
          }else if(activitiesObj.name ){
            setDescriptionCategory((activitiesObj && activitiesObj.description) ? activitiesObj.description : i18n.t("products_search.subtext"))
            setStringSearch(i18n.t('searches.all_category_products_no_result', {category_name: (activitiesObj ? activitiesObj.name : "")}))
          }
        }
        setTitleSearch([(activitiesObj ? activitiesObj.name : ""), (regionsObj ? regionsObj.name : ""), (timeValue_t ? i18n.t("excursions." + timeValue_t) : ""), (dateValue_t ? dateValue_t.toLocaleDateString() : "")].filter(Boolean).join(" " + i18n.t("products_search.in") + " "));

        
        if(json.no_results_found){     
          if(json.optinal_results_text == "activity"){
            setOptinalResultsText((activityObject ? activityObject.name : ""))
          }
          else if(json.optinal_results_text == "region"){
            setOptinalResultsText((regionObject ? regionObject.name : ""))
          }
          else if(json.optinal_results_text == "time"){
            setOptinalResultsText((timeValue ? i18n.t("excursions." + timeValue) : ""))
          }else{
            setOptinalResultsText("")
          }
          setOptinalResults(json.no_results_found)
          setOptionalProducts(json.optinal_results)
          
        }
      }
      )
      .catch(error => console.log(error));
  }

  function createDataLayer(excursions){
    window.dataLayer.push({ ecommerce: null }); 
    	var new_products = []; 
      excursions && excursions.map((product) => {
        new_products.push(
          {
            'item_id': product.id,
            'item_name': product.title,
            'price': mkt_money(product),
            'item_brand': (product.agency ? product.agency?.name : "Outdoor Index"),
            'item_category': (product.zone && product.zone.name || ""),
            'item_variant': 'Excursion',
            'affiliation': 'Outdoor index',
            'quantity': '1'
          }
        ) // Clear the previous ecommerce object.
    })
    window.dataLayer.push({
      'event': "view_item_list",
      'ecommerce': {
        'item_list_id': "products_list",
        'item_list_name': "Products List",
        'items': new_products
       }
    });
  }
  return (
    <ThemeProvider theme={themeViewnia}>
      <section className="home-section">
          
          {
              stringSearch && stringSearch.trim() !== '' &&
              <section className="head-text">
                  <h1>{titleSearch}</h1>
                  <p>{descriptionCategory}</p>
              </section>
          }

          <div className={"search-buttons"}>
            <FormControl  variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="grouped-select">{i18n.t("products_search.activities")}</InputLabel>
              <Select className="search-first-select" defaultValue="" id="grouped-select"
                value={activityValue}
                onChange={(e) => setActivityValue(e.target.value)}
                renderValue={(selected) => (
									<div className="">
									{activities.find(activity => activity.id == selected) && activities.find(activity => activity.id == selected).name} 
									</div>
								)}
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                >
                {activities && activities.map((activity) => {
                  const labelId = `activities-label-${activity.id}`;
                  return (
                    <MenuItem key={labelId} value={activity.id}>
                      <Radio color="primary" checked={activityValue == activity.id} />
                      {activity.name}
                    </MenuItem>
                  );
                 })}
              </Select>
            </FormControl>
            <FormControl  variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="grouped-select">{i18n.t("products_search.regions")}</InputLabel>
              <Select defaultValue="" id="grouped-select"
                value={regionValue}
                onChange={(e) => setRegionValue(e.target.value)}
                renderValue={(selected) => (
									<div className="">
									{regions.find(region => region.id == selected) && regions.find(region => region.id == selected).name} 
									</div>
								)}
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                >
                {regions && regions.map((region) => {
                  const labelId = `regions-label-${region.id}`;
                  return (
                    <MenuItem key={labelId} value={region.id}>
                      <Radio color="primary" checked={regionValue == region.id} />
                      {region.name}
                    </MenuItem>
                  );
                 })}
              </Select>
            </FormControl>
            <FormControl  variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="grouped-select">{i18n.t("products_search.time")}</InputLabel>
              <Select defaultValue="" id="grouped-select"
                value={timeValue}
                onChange={(e) => setTimeValue(e.target.value)}
                renderValue={(selected) => (
									<div className="">
									  {i18n.t("excursions." + selected)} 
									</div>
                  )}
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                >
                <MenuItem value={"half_day"}><Radio color="primary" checked={timeValue == "half_day"} />{i18n.t("excursions.half_day")}</MenuItem>
                <MenuItem value={"full_day"}><Radio color="primary" checked={timeValue == "full_day"} />{i18n.t("excursions.full_day")}</MenuItem>
                <MenuItem value={"multiple_days"}><Radio color="primary" checked={timeValue == "multiple_days"} />{i18n.t("excursions.multiple_days")}</MenuItem>
              </Select>
            </FormControl>
            <FormControl  variant="outlined" className={classes.formControl}>
            <Button variant="contained" color="secondary" onClick={() => search()}>
            <i className="fa fa-search" aria-hidden="true"></i>
        </Button>
            </FormControl>
          </div>
          
          {(props.home_products && !isSearch) && 
            <>
              <br /><br />
              <header><h2>{props.home_title}</h2></header>
                <Grid container spacing={2}>
                  {props.home_products && props.home_products.map((product) => {
                      const labelId = `products-label-${product.id}`;
                      return (
                        <Grid item xs={12} sm={6} md={3}>
                          <Product
                            key={labelId}
                            product={product}
                            image_format={"large"}
                          />
                        </Grid>
                      );
                  })}
                </Grid>      
            </>
          }

          {loading && 
            <section id="products-loader" className={classes.root}>     
              <Grid container spacing={2}>
                {
                  Array.from(new Array(8)).map((item, index) => (
                    <Grid key={"skeleton" + index} item xs={12} sm={6} md={3} className='posts'>
                      <Skeleton variant="rect" className="lazyload" height={(352)} animation={false} />
                      <Skeleton variant="rect" className="lazyload info-slide product-skeleton-line" height={10} animation={false} />
                      <Skeleton variant="rect" className="lazyload product-skeleton-line" height={10} animation={false} />
                      <Skeleton variant="rect" className="lazyload product-skeleton-line" height={10} animation={false} />
                    </Grid>
                  ))
                }
              </Grid>
            </section> ||

            <section className="products-section">
              <Grid container direction="row"
                    justify="space-between"
                    alignItems="center" 
                    spacing={2}>
                  <Grid item xs={12} sm={8}>
                  <br></br>  
                  <br></br>        
                    <h2>
                      {
                        stringSearch &&
                        <>
                          {stringSearch} ({products.length})
                          </>
                        ||
                        <>
                        {i18n.t("products_search.all_adventures")} ({products.length})
                        </>
                      }
                    </h2>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                  <br></br>  
                  <br></br> 
                    <h4>
                    <Button className="product-clear" onClick={() => clear()}>
                      {i18n.t("products_search.clear")}
                    </Button>
                    </h4>  
                  </Grid>
              </Grid>
              <Grid container spacing={2}>
                {products && products.map((product) => {
                    const labelId = `products-label-${product.id}`;
                    return (
                      <Grid item xs={12} sm={6} md={3}>
                        <Product
                          key={labelId}
                          product={product}
                          image_format={"large"}
                        />
                      </Grid>
                    );
                })}
              </Grid>
                  
            </section>
          }

          {!loading && optional_products.length > 0 &&
            <section className="products-section">
              <Grid container direction="row"
                    justify="space-between"
                    alignItems="center" 
                    spacing={2}>
                  <Grid item xs={12} sm={8}>  
                  <br></br>  
                  <br></br>    
                    <h2>
                      {
                        optinal_results_text &&
                        <>
                          {optinal_results_text} ({optional_products.length})
                          </>
                        ||
                        <>
                        {i18n.t("products_search.all_adventures")} ({optional_products.length})
                        </>
                      }
                    </h2>
                  </Grid>
              </Grid>
              <Grid container spacing={2}>
              {optional_products && optional_products.map((product) => {
                  const labelId = `products-label-${product.id}`;
                  return (
                    <Grid item xs={12} sm={6} md={3}>
                      <Product
                        key={labelId}
                        product={product}
                        image_format={"large"}
                      />
                    </Grid>
                  );
              })}
            </Grid>
            </section>
          } 
      </section>
    </ThemeProvider>


  );
}

export default Products